import React, {useEffect, useState} from 'react';
import {
    DetailsList,
    DetailsListLayoutMode,
    Dropdown,
    IColumn,
    SelectionMode,
    TextField
} from "@fluentui/react";
import {cloneDeep} from "lodash";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {useGetMetadataQuery} from "../../store/Api";
import AttributeDivider from "../Common/AttributeDivider";
import {IProductBase} from "../../models/Search";
import CurrencyDisplay from '../Currency/CurrencyDisplay';

interface IBasicDetailsTableProps {
    product: IProductBase;
    onChange?: (product: IProductBase) => void;
    editMode?: boolean;
    catalog: string;
}

const BasicDetailsTable: React.FC<IBasicDetailsTableProps> = (props) => {
    const metadata = useGetMetadataQuery(props.catalog);
    const [segments, setSegments] = useState<{ key: string, text: string }[]>([]);
    const [newSegmentEnabled, setNewSegmentEnabled] = useState(false);
    const [msrp, setMsrp] = useState<string | undefined>(props.product.msrp?.toString());

    useEffect(() => {
        if (metadata.data?.segments === undefined) return;
        setSegments([
            ...Object.keys(metadata.data?.segments).map(d => ({
                key: d,
                text: d
            })),
            {key: 'NEW', text: 'New Segment'}
        ])
    }, [metadata.data?.segments])

    useEffect(() => {
        setMsrp(props.product.msrp?.toString());
    }, [props.product.msrp]);

    const basicDetails = [
        {
            key: 'Name',
            value: props.product.name,
            edit: <TextField
                value={props.product.name}
                disabled={!!props.product.descriptionSource}
                onChange={(e, d) => {
                    if (!props.onChange) return;
                    const newProduct = cloneDeep(props.product);
                    newProduct.name = d ?? '';
                    props.onChange(newProduct);
                }}
            />,
        },
        {
            key: 'Manufacturer',
            value: props.product.manufacturer,
            edit: <TextField
                value={props.product.manufacturer}
                disabled={!!props.product.descriptionSource}
                onChange={(e, d) => {
                    if (!props.onChange) return;
                    const newProduct = cloneDeep(props.product);
                    newProduct.manufacturer = d ?? '';
                    props.onChange(newProduct);
                }}
            />,
        },
        {
            key: 'Manufacturer Part Number',
            value: props.product.manufacturerPartNumber,
            edit: <TextField
                value={props.product.manufacturerPartNumber}
                onChange={(e, d) => {
                    if (!props.onChange) return;
                    const newProduct = cloneDeep(props.product);
                    newProduct.manufacturerPartNumber = d ?? '';
                    props.onChange(newProduct);
                }}
            />,
        },
        {
            key: 'Internal Part Number',
            value: props.product.internalPartNumber,
            edit: <TextField
                value={props.product.internalPartNumber}
                onChange={(e, d) => {
                    if (!props.onChange) return;
                    const newProduct = cloneDeep(props.product);
                    newProduct.internalPartNumber = d ?? '';
                    props.onChange(newProduct);
                }}
            />,
        },
        {
            key: 'MSRP',
            value: <CurrencyDisplay value={props.product.msrp} />,
            edit: <TextField
                value={msrp}
                onChange={(e, d) => {
                    if (!props.onChange) return;
                    setMsrp(d);
                }}
                onBlur={() => {
                    if (!props.onChange) return;
                    const newProduct = cloneDeep(props.product);
                    newProduct.msrp = parseFloat(msrp ?? '0');
                    props.onChange(newProduct);
                }}
            />,
        },
        {
            key: 'Segment',
            value: props.product.segment,
            edit: <>
                <Dropdown
                    options={segments}
                    disabled={metadata.isLoading || !!props.product.descriptionSource}
                    selectedKey={newSegmentEnabled ? 'NEW' : props.product.segment}
                    onChange={(e, d) => {
                        if (!props.onChange) return;
                        if (d?.key === 'NEW') setNewSegmentEnabled(true)
                        else {
                            setNewSegmentEnabled(false);
                            const newProduct = cloneDeep(props.product);
                            newProduct.segment = d?.key.toString() ?? '';
                            props.onChange(newProduct);
                        }
                    }}
                />
                {newSegmentEnabled && <TextField
                    value={props.product.segment}
                    onChange={(e,d) => {
                        if (!props.onChange) return;
                        const newProduct = cloneDeep(props.product);
                        newProduct.segment = d ?? '';
                        props.onChange(newProduct);
                    }}
                    />}
            </>,
        },
        {
            key: 'Category',
            value: props.product.category,
            edit: <TextField
                value={props.product.category}
                disabled={!!props.product.descriptionSource}
                onChange={(e, d) => {
                    if (!props.onChange) return;
                    const newProduct = cloneDeep(props.product);
                    newProduct.category = d ?? '';
                    props.onChange(newProduct);
                }}
            />,
        },
    ];

    const columns: IColumn[] = [
        {...ColumnGenerator('Data', 'key', 200), flexGrow: 1},
        {
            ...ColumnGenerator('Value', 'value', 200, undefined, (item) => props.editMode ? item.edit : item.value),
            flexGrow: 1
        }
    ]

    return (
        <>
            <div className={"ac-attribute-segment"}>
                <AttributeDivider title={"Basic Details"}></AttributeDivider>
                <DetailsList
                    className={"ac-attribute-container"}
                    items={basicDetails}
                    columns={columns}
                    isHeaderVisible={false}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                />
            </div>
        </>
    )
}

export default BasicDetailsTable;
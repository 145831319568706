import React from 'react';
import { INormalizedProduct } from '../../models/NormalizedProduct';
import { Dropdown, Icon, IDropdownOption, Label, TooltipHost } from '@fluentui/react';
import { useGetAllVendorSettingsQuery } from '../../store/Api';

interface IDescriptionSourceSelectionProps {
    product: INormalizedProduct;
    onSelectionChanged: (source: string) => void;
    editMode?: boolean;
}

const DescriptionSourceSelection: React.FC<IDescriptionSourceSelectionProps> = (props) => {
    const vendorSettings = useGetAllVendorSettingsQuery();

    const onChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (!option) return;
        props.onSelectionChanged(option.key as string);
    }

    if (!props.product.vendors) return null;
    return (
        <Dropdown
            onRenderLabel={() => <Label>
                Description Source&nbsp;
                <TooltipHost content='If set, the product name, description, manufacturer, and categorization will be updated from the selected vendor during scheduled extension imports.'>
                    <Icon iconName='Info' />
                </TooltipHost>
            </Label>}
            options={[
                { key: '', text: 'None' },
                ...props.product.vendors.map(v => ({
                    key: v.vendorName,
                    text: vendorSettings.data?.items.find(vs => vs.sourceVendor === v.vendorName)?.vendorDisplayName ?? v.vendorName
                }))
            ]}
            selectedKey={props.product.descriptionSource ?? ''}
            onChange={onChange}
            disabled={!props.editMode}
        />
    )
}

export default DescriptionSourceSelection;
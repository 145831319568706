import React, {useEffect} from "react";
import "./NavMenu.css";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {
    useClearExtensionsMutation,
    useClearSearchMutation,
    useClearWorkspaceCacheMutation, useGetCatalogsQuery,
    useGetOrganizationsQuery,
    useSetSelectedOrganizationMutation
} from "../../store/Api";
import {
    CommandBar, ContextualMenuItemType, ICommandBarItemProps,
    IStyle,
    Layer,
    Spinner
} from "@fluentui/react";
import {useHistory} from "react-router-dom";
import {clearSettings} from "../../store/settingsSlice";
import UserDropdown from "./UserDropdown";
import {tracker} from "../../store/store";
import CAN, {LLM_ANALYSIS, PROCUREMENT} from "../../permissions/ability";
import AIPanel from "../AdaptiveIntelligence/AIPanel";
import {updateSelectedCatalog} from "../../store/searchSlice";

const NavMenu: React.FC = () => {
    const dispatch = useDispatch();
    const getOrganizations = useGetOrganizationsQuery();
    const [clearWorkspaces] = useClearWorkspaceCacheMutation();
    const [clearSearch] = useClearSearchMutation();
    const [setOrganization] = useSetSelectedOrganizationMutation();
    const [clearExtensions] = useClearExtensionsMutation();
    const selectedOrganization = useSelector((state: RootState) => state.settings.selectedOrganization);
    const history = useHistory();
    const canAccessProcurement = CAN(PROCUREMENT)
    const canAI = CAN(LLM_ANALYSIS)
    const [aiPanel, setAIPanel] = React.useState<boolean>(false);
    const selectedOrg = selectedOrganization ?? (getOrganizations.data ? getOrganizations.data[0] : undefined);
    const getCatalogs = useGetCatalogsQuery();
    const whiteLabel = window.config.overrideLogo;
    const title = window.config.title ?? "";
    
    const navMenuStyle: IStyle = {
        padding: 0,
        height: '48px',
        borderBottom: '1px solid #ccc',
        boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    }

    const buttonStyle: IStyle = {
    }

    const imageStyle: React.CSSProperties = {
        padding: '3px',
        height: '35px'
    }

    const openAIPanel = () => {
        setAIPanel(true);
    }
    const closeAIPanel = () => {
        setAIPanel(false);
    }

    let orgDisplay: ICommandBarItemProps = {
        key: 'organizations',
        text: 'Loading',
        iconOnly: true,
        onRenderIcon: () => <Spinner/>
    }
    
    useEffect(() => {
        if(getCatalogs.data && selectedOrg) {
            const currentCatalog = getCatalogs?.data.find(a => a.name == selectedOrg.defaultCatalog)
            console.log('settings current catalog!', currentCatalog)
            dispatch(updateSelectedCatalog(currentCatalog));
        }
    }, [selectedOrg, getCatalogs.data])
    
    if (!getOrganizations.isLoading && getOrganizations.data) {
        tracker.setMetadata('organization', selectedOrg?.id ?? 'No Organization');
        orgDisplay = {
            key: 'organizations',
            text: selectedOrg?.name ?? 'Organization',
            iconOnly: true,
            onRenderIcon: () => selectedOrg?.logo ? <img
            alt={`${selectedOrg.name} logo`}
            src={selectedOrg.logo}
            style={imageStyle}
            /> : null,
            subMenuProps: getOrganizations.data.length > 1 ? {
            items: getOrganizations.data
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
                .map(org => ({
                key: org.id,
                text: org.name,
                onClick: () => {
                    setOrganization(org);
                    dispatch(clearSettings());
                    clearSearch();
                    clearWorkspaces();
                    clearExtensions();
                }
                }))
            } : undefined,
        }

    }

    
    
    const getCommands = () => {
        let commands: ICommandBarItemProps[] = []
        if(whiteLabel){
            commands = [
                {
                    key: 'Home',
                    text: 'Home',
                    iconOnly: true,
                    iconProps: {iconName: 'Home'},
                    onClick: () => {
                        history.push('/');
                    }
                },
                {
                    key: 'Logo',
                    iconOnly: true,
                    onRenderIcon: () => <img
                        src={whiteLabel}
                        alt={title}
                        style={imageStyle}
                    />,
                    disabled: true
                }
            ]
        }
        else{
            commands = [
                {
                    key: 'Logo',
                    text: 'Home',
                    iconOnly: true,
                    onRenderIcon: () => <img
                        src="mainLogo.png"
                        alt='Adaptive Catalog logo'
                        style={imageStyle}
                    />,
                    onClick: () => {
                        history.push('/');
                    }
                },
                orgDisplay
            ]
        }
        return commands;
    }

    const isSettingsPage = window.location.pathname.toLowerCase().indexOf('/settings') !== -1;
    const isSearchPage = !isSettingsPage && window.location.pathname.toLowerCase().indexOf('/search') !== -1;
    const isWorkspacePage = !isSettingsPage && window.location.pathname.toLowerCase().indexOf('/workspace') !== -1;
    const isProcurementPage = !isSettingsPage && window.location.pathname.toLowerCase().indexOf('/procurement') !== -1;
    
    const getRightCommands = () => {
        const commandBuilder: ICommandBarItemProps[] = [
            {
                key: 'Search',
                text: 'Search',
                buttonStyles: {
                    root: {
                        ...buttonStyle,
                        backgroundColor: isSearchPage ? '#ddd' : 'white'
                    }
                },
                onClick: () => {
                    history.push('/search');
                },
            },
            {
                key: 'Workspace',
                text: 'Workspace',
                buttonStyles: {
                    root: {
                        ...buttonStyle,
                        backgroundColor: isWorkspacePage ? '#ddd' : 'white'
                    }
                },
                onClick: () => {
                    history.push('/workspace/last');
                },
            }
        ]
        if(selectedOrg?.procurementEnabled === true && canAccessProcurement){
            commandBuilder.push(                {
                key: 'Procurement',
                text: 'Procurement',
                subMenuProps: {
                    items: [
                        {
                            key: "prepareOrders",
                            text: "Prepare Orders",
                            itemType: ContextualMenuItemType.Normal,
                            onClick: () => {
                                history.push('/procurement/prepare')}
                        },
                        {
                            key: "orders",
                            text: "Orders",
                            itemType: ContextualMenuItemType.Normal,
                            onClick: () => {
                                history.push('/procurement/orders')
                            }
                        },
                        {
                            key: "completed",
                            text: "Completed Orders",
                            itemType: ContextualMenuItemType.Normal,
                            onClick: () => {
                                history.push('/procurement/completed')
                            }
                        }
                        ]
                },
                buttonStyles: {
                    root: {
                        ...buttonStyle,
                        backgroundColor: isProcurementPage ? '#ddd' : 'white'
                    }
                },
                onClick: () => {
                    history.push('/procurement/prepare');
                },
            })
        }
        if (canAI) {
            commandBuilder.push(
                {
                    key: 'AI',
                    iconOnly: true,
                    iconProps: {iconName: 'ChatBot'},
                    onClick: openAIPanel
                }
            )
        }

        commandBuilder.push(
            {
                key: 'Settings',
                iconOnly: true,
                onRenderIcon: () => <UserDropdown />,
                buttonStyles: {
                    root: {
                        ...buttonStyle,
                        backgroundColor: isSettingsPage ? '#ddd' : 'white'
                    }
                },
            }
        )
        return commandBuilder;
    };
    return (
            <header>
                <Layer>
                    <div className="nav-menu">
                        <CommandBar
                            styles={{
                                root: navMenuStyle,
                            }}
                            items={getCommands()}
                            farItems={getRightCommands()}
                        />
                    </div>
                </Layer>
                <AIPanel isOpen={aiPanel} onClose={closeAIPanel}/>
            </header>
    );
}

export default NavMenu;